body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.landingpage--container {
  margin-top: 0;

  height: 100vh;

  background-image: url('https://zayland-assets.s3.amazonaws.com/images/background.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.homepage--container {
  margin-top: 0;
  min-height: 100vh;
  height: 100%;
}

.nobody-knows-bg {
  background-image: url('https://zayland-assets.s3.amazonaws.com/images/blank_background.png');
  background-position: center;
  background-repeat: repeat;
}

.remixes-bg {
  background-image: url('https://zayland-assets.s3.amazonaws.com/images/BG-min.jpg');
  background-position: center;
  background-repeat: repeat;
}

.rotate {
  transform-origin: center center;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
}

div.menu-button {
  display: block;
  transition: all 0.4s ease-in-out;
  line-height: 1.4;
}
.bg-zay {
  min-height: 100vh;
  height: 100%;

  background-image: url('http://api.thumbr.it/whitenoise-361x370.png?');
  background: #000000;
  background-position: center;
  background-repeat: repeat;
}

.bg-wish-we-had-a-chance {
  min-height: 100vh;
  height: 100%;

  background-image: url('https://zayland-uploads.s3.amazonaws.com/bg-wish-min.gif');
  //   background: #000000;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-dallas-wave {
  min-height: 100vh;
  height: 100%;

  background-image: url('../images/SITE\ BACKGROUND.jpg');
  // background: #000000;
  background-position: center;
  background-repeat: repeat;
}

.bg-mileage {
  min-height: 100vh;
  height: 100%;

  background-image: url('https://zayland-uploads.s3.amazonaws.com/IMG_8266_min_3ced0d5027.gif');
  // background: #000000;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.song-player {
  display: grid;
  grid-template-rows: 2rem 1fr;
  grid-template-columns: calc(80px - 2rem) 1fr calc(80px - 2rem);
  grid-template-areas:
    'title   title     title'
    'btn     progress  download';

  width: 50vmin;
  height: 80px;

  bottom: 5rem;
  left: 4rem;
}

.title {
  margin: 0;
  grid-area: title;
  grid-column: span 3;
  border: 1px solid white;
}

.bar__progress {
  flex: 1;
  height: calc(80px - 2rem);
  width: 1px;
  background-color: white;
  display: flex;
  align-items: center;
  cursor: pointer;

  .bar__progress--knob {
    position: relative;
    height: 8px;
    width: 8px;
    border: 1.5px solid white;
    border-radius: 50%;
    background-color: red;
  }
}
@media screen and (min-width: 30em) and (max-width: 60em) {
  .vh-85-m {
    height: 85vh;
  }
}

@media screen and (max-width: 30em) {
  .player {
    height: 50vmin;
  }
  .song-player {
    width: 80vmin;
    left: 1rem;
  }
}

@media screen and (min-width: 60em) {
  .player {
    height: 50vmin;
  }
}
